<template>
  <div class="bet-ticker-main">
    <BetTickerPageHeader />
    <BetTickerDisabledView v-if="selectedBetTicker && !selectedBetTicker?.enabled" />
    <BetTickerTable v-else />
    <BetTickerSidebar />
    <div
      class="load-older-bets"
      @click.stop="loadOlderBets"
      v-if="hasAvailableBets"
    >
      <Spinner
        v-if="loading"
        small
      />
      <span v-else>Load older bets</span>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, computed } from 'vue';
import { useStore } from 'vuex';
import Spinner from '@/components/common/Spinner';
import betTickerHelper from './betTicker-helper';
import BetTickerTable from './BetTickerTable';
import BetTickerPageHeader from './BetTickerPageHeader';
import BetTickerSidebar from './sidebar/BetTickerSidebar';
import BetTickerDisabledView from './BetTickerDisabledView';

export default {
  components: {
    BetTickerTable,
    BetTickerPageHeader,
    BetTickerSidebar,
    Spinner,
    BetTickerDisabledView,
  },
  setup() {
    const store = useStore();
    const initialize = () => {
      store.dispatch('betTicker/loadBetTickerConfigurations');
      store.dispatch('updateColumns', { ...betTickerHelper.createColumns() });
    };
    const finalize = () => {
      store.dispatch('updateColumns', {});
      store.dispatch('betTicker/unloadBetTicker');
    };

    const selectedBetTicker = computed(() => store.getters['betTicker/selectedBetTicker']);
    const loading = computed(() => store.getters['betTicker/betTickerListLoading']);
    const hasAvailableBets = computed(() => store.getters['betTicker/betTickerList']?.length);

    const loadOlderBets = () => {
      if (loading.value) return;
      if (selectedBetTicker.value) {
        store.dispatch('betTicker/loadBetTickerBets', { betTickerId: selectedBetTicker.value.betTickerId, loadOlder: true });
        return;
      }
      store.dispatch('betTicker/loadAllBets', true);
    };

    onMounted(initialize);
    onUnmounted(finalize);

    return {
      loadOlderBets,
      loading,
      selectedBetTicker,
      hasAvailableBets,
    };
  },
};
</script>

<style lang="scss">
    .bet-ticker-main {
      position: relative;
      display: block;
      overflow: hidden;

      .load-older-bets {
        position: absolute;
        bottom: 30px;
        right: 50px;
        height: 32px;
        padding: 0 8px;
        z-index: 999;
        border-radius: 4px;
        border: 1px solid #DDD;
        background: #FFF;
        box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 255, 255, 0.85);
        }
      }
    }
</style>
