<template>
  <div
    class="bet-ticker-item"
    ref="betTickerRef"
  >
    <Tooltip
      v-if="!betTicker.enabled"
      text="Disabled ticker"
      right
    >
      <Icon
        name="disable"
      />
    </Tooltip>
    <span
      v-show="!renameActionActive"
    >
      {{ betTicker.betTickerName }}
    </span>
    <input
      v-show="renameActionActive"
      class="bet-ticker-name-input"
      type="text"
      :value="betTickerNameInputValue"
      @keypress.enter="unFocusInput"
      @keydown.esc="cancelRename"
      @blur="renameBetTicker"
      @input="changeBetTickerName($event.target.value)"
      ref="betTickerInputRef"
    >
    <div
      class="menu-overlay"
    >
      <Icon
        name="dots-vertical"
        @click.stop="() => { betTickerMenuToggled = !betTickerMenuToggled }"
      />
    </div>
    <div
      class="bet-ticker-dropdown"
      v-if="betTickerMenuToggled"
    >
      <div
        class="bet-ticker-dropdown-option"
        @click.stop="actionClicked('rename')"
      >
        <Icon name="pencil" />
        Rename
      </div>
      <div
        class="bet-ticker-dropdown-option"
        @click.stop="actionClicked('duplicate')"
      >
        <Icon
          name="copy"
          class="duplicate"
        />
        Duplicate
      </div>
      <div
        :class="['bet-ticker-dropdown-option', { 'danger': betTickerEnabled }]"
        @click.stop="actionClicked('toggle-ticker')"
      >
        <Icon :name="betTickerEnabled ? 'disable' : 'check-circle'" />
        {{ betTickerEnabled ? 'Disable ticker' : 'Enable ticker' }}
      </div>
      <div
        class="bet-ticker-dropdown-option danger"
        @click.stop="actionClicked('delete')"
      >
        <Icon name="trash" />
        Delete
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Icon,
    Tooltip,
  },
  props: {
    betTicker: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();

    const betTickerRef = ref(null);
    const betTickerInputRef = ref(null);
    const betTickerMenuToggled = ref(false);
    const renameActionActive = ref(false);
    const betTickerNameInputValue = ref(cloneDeep(props.betTicker.betTickerName));
    const betTickerEnabled = computed(() => props.betTicker.enabled);
    const actionClicked = (action) => {
      if (action !== 'rename') {
        store.dispatch('betTicker/betTickerAction', { betTicker: props.betTicker, action });
        betTickerMenuToggled.value = false;
        return;
      }
      // action is rename
      renameActionActive.value = true;
      betTickerMenuToggled.value = false;
      const focusTimeout = setTimeout(() => {
        betTickerInputRef.value.focus();
        clearTimeout(focusTimeout);
      }, 50);
    };

    const renameBetTicker = () => {
      if (betTickerNameInputValue.value === props.betTicker.betTickerName) {
        renameActionActive.value = false;
        return;
      }
      store.dispatch('betTicker/betTickerAction', {
        action: 'rename',
        betTicker: props.betTicker,
        name: betTickerNameInputValue.value,
      });
      renameActionActive.value = false;
    };

    const cancelRename = () => {
      renameActionActive.value = false;
      betTickerNameInputValue.value = cloneDeep(props.betTicker.betTickerName);
    };

    const changeBetTickerName = (value) => {
      betTickerNameInputValue.value = value;
    };

    const unFocusInput = () => {
      betTickerInputRef.value.blur();
    };

    onClickOutside(betTickerRef, () => {
      betTickerMenuToggled.value = false;
      renameActionActive.value = false;
    });

    return {
      betTickerRef,
      betTickerMenuToggled,
      actionClicked,
      betTickerNameInputValue,
      renameBetTicker,
      betTickerInputRef,
      cancelRename,
      changeBetTickerName,
      renameActionActive,
      unFocusInput,
      betTickerEnabled,
    };
  },
};
</script>
<style lang="scss">

.bet-ticker-item {
  .bet-ticker-name-input {
    height: 100%;
    width: 100%;
    outline: none;
    background: transparent;
  }

  .bet-ticker-dropdown {
    width: 180px;
    position: absolute;
    top: 44px;
    left: 8px;
    z-index: 999;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px #19141414;
    padding: 4px 0;
    min-height: 40px;
    background: #fff;

    .bet-ticker-dropdown-option {
      display: flex;
      height: 32px;
      padding: 0 8px;
      align-items: center;

      &:hover {
        background-color: rgba(0,0,0, 0.03);
        cursor: pointer;
      }

      .icon {
        height: 14px;
        width: 14px;
        margin-right: 4px;

        &.duplicate {
          svg {
            path {
              stroke-width: 1;
              stroke: #191414;
            }
          }
        }
      }

      &.danger {
        color: #FF2E2D;
        .icon {
          svg {
            path {
              fill: #FF2E2D;
            }
          }
        }
      }
    }
  }
}

</style>
