<template>
  <div class="bet-ticker-disabled-view-root">
    <Icon :name="'empty-state'" />
    <h6>Disabled ticker</h6>
    <p>No bets available for this ticker because it has been disabled</p>
    <Button
      class="enable-ticker-btn"
      variant="primary"
      @click="enableBetTicker"
    >
      Enable ticker
    </Button>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { find } from 'lodash';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';

export default {
  components: {
    Icon,
    Button,
  },
  setup() {
    const store = useStore();
    const betTickerConfigurations = computed(() => store.getters['betTicker/betTickerConfigurations']);

    const enableBetTicker = () => {
      const selectedBetTickerConfiguration = find(betTickerConfigurations.value, (ticker) => ticker.betTickerId === store.getters['betTicker/selectedBetTicker']?.betTickerId);
      store.dispatch('betTicker/betTickerAction', { betTicker: selectedBetTickerConfiguration, action: 'toggle-ticker' });
    };
    return {
      enableBetTicker,
    };
  },
};
</script>

  <style lang="scss">
  .bet-ticker-disabled-view-root {
    max-width: 400px;
    margin: 100px auto 0 auto;
    text-align: center;
    h6 {
      margin: 20px 0;
    }
    p {
      max-width: 300px;
      margin: 0 auto;
    }
    .enable-ticker-btn {
      margin-top: 20px;
    }
  }
  </style>
