<template>
  <Table
    class="betTicker-table"
    :columns="columns"
    :rows="bets"
    :loading="loading"
  >
    <template #cell="{ column, row }">
      <BetTickerTableCell
        :column-key="column.key"
        :bet="row"
      />
    </template>
  </Table>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Table from '@/components/common/Table';
import BetTickerTableCell from './table-cell/BetTickerTableCell';

export default {
  components: {
    Table,
    BetTickerTableCell,
  },
  setup() {
    const store = useStore();
    const columns = computed(() => store.getters.columns);
    const bets = computed(() => store.getters['betTicker/betTickerList']);
    const loading = computed(() => store.getters['betTicker/betTickerListLoading']);
    return {
      columns,
      bets,
      loading,
    };
  },
};
</script>

<style lang="scss">
.betTicker-table {
  height: calc(100% - $betTickerHeaderPageHeight);
  .table-head {
    .table-cell--odds span,
    .table-cell--stake span,
    .table-cell--potentialPayout span,
    .table-cell--freeStake span {
      text-align: right;
    }
  }
  .table-body {
    .table-cell--odds,
    .table-cell--stake,
    .table-cell--potentialPayout,
    .table-cell--freeStake
    {
      .bet-ticker-cell {
        width: 100%;
        text-align: right;
      }
    }
  }

}
</style>
