<template>
  <div class="betTicker-header">
    <div class="ticker-container">
      <div
        :class="['ticker-configuration', {'selected': betTicker.betTickerId === selectedBetTicker?.betTickerId}]"
        v-for="betTicker in filteredBetTickerConfigurations"
        :key="betTicker.betTickerId"
        @click="selectBetTicker(betTicker)"
      >
        <BetTickerItem :bet-ticker="betTicker || {}" />
      </div>
      <div class="new-ticker-container">
        <div
          :class="['new-ticker', {'no-tickers': !betTickerConfigurations?.length}]"
          @click="createBetTicker"
        >
          <Icon name="plus" />
          New Ticker
        </div>
        <div
          class="ticker-menu-dots"
          ref="betTickerRef"
        >
          <Icon
            name="dots-vertical"
            @click="() => { betTickerMenuToggled = !betTickerMenuToggled }"
          />
        </div>
        <div
          class="ticker-menu-dropdown"
          v-if="betTickerMenuToggled"
        >
          <div
            class="ticker-menu-option"
            @click.stop="toggleShowDisabledTickers"
          >
            <Icon :name="showDisabledTickers ? 'eye-off' : 'eye'" />
            {{ showDisabledTickers ? 'Hide disabled tickers' : 'Show disabled tickers' }}
          </div>
        </div>
      </div>
    </div>
    <div class="filter-container">
      <div
        class="flagged-bets-filter"
        @click.stop="setOnlyFlaggedBets(!onlyFlaggedBets)"
      >
        <Toggle
          :model-value="onlyFlaggedBets"
          @update:model-value="setOnlyFlaggedBets"
        />
        <div class="flagged-bets-filter__label">
          Show flagged bets
        </div>
      </div>
      <!-- <BetTickerSearch /> -->
      <Button
        icon="three-columns"
        @click="setActiveSidebar(SIDEBAR_OPTIONS.COLUMNS)"
      >
        Columns
      </Button>
      <Button
        v-if="selectedBetTicker?.enabled"
        icon="filters"
        @click="setActiveSidebar(SIDEBAR_OPTIONS.FILTERS)"
        :disabled="!selectedBetTicker"
      >
        Filters
      </Button>
      <Button
        icon="highlights"
        @click="setActiveSidebar(SIDEBAR_OPTIONS.HIGHLIGHTS)"
        :disabled="!selectedBetTicker"
        v-if="selectedBetTicker?.enabled"
      >
        Highlights
      </Button>
      <div
        title="Refresh Bet Ticker page"
        :class="['refresh-bets-button', {'disabled': !hasHighlightedBets }]"
        @click="refreshHighlightedBets"
        v-if="selectedBetTicker?.enabled"
      >
        <Icon name="refresh-ccw-1" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { find, filter } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Toggle from '@/components/common/Toggle';
import { SIDEBAR_OPTIONS } from './betTicker-helper';
import BetTickerItem from './BetTickerItem';
/* import BetTickerSearch from './BetTickerSearch'; */

export default {
  components: {
    Button,
    Icon,
    Toggle,
    BetTickerItem,
    /* BetTickerSearch, */
  },
  setup() {
    const store = useStore();
    const betTickerRef = ref(null);
    const setActiveSidebar = (sidebarOption) => store.dispatch('betTicker/setActiveSidebarOption', sidebarOption);
    const betTickerConfigurations = computed(() => store.getters['betTicker/betTickerConfigurations']);
    const showDisabledTickers = computed(() => store.getters['betTicker/showDisabledTickers']);
    const filteredBetTickerConfigurations = computed(() => {
      const allConfigurations = store.getters['betTicker/betTickerConfigurations'];

      if (showDisabledTickers.value) {
        return allConfigurations;
      }
      return filter(allConfigurations, (configuration) => configuration.enabled);
    });
    const selectedBetTicker = computed(() => store.getters['betTicker/selectedBetTicker']);
    const betTickerMenuToggled = ref(false);
    const selectBetTicker = (betTicker) => {
      if (betTicker.betTickerId === selectedBetTicker.value?.betTickerId) return;
      store.dispatch('betTicker/selectBetTicker', betTicker);
    };

    const createBetTicker = () => {
      store.dispatch('betTicker/createBetTicker');
    };

    const toggleShowDisabledTickers = () => {
      betTickerMenuToggled.value = false;
      store.dispatch('betTicker/toggleShowDisabledTickers');
    };

    const hasHighlightedBets = computed(() => {
      const bets = store.getters['betTicker/betTickerList'];
      return !!find(bets, (bet) => bet.highlightedRow);
    });

    const refreshHighlightedBets = () => {
      if (!hasHighlightedBets.value) return;
      store.dispatch('betTicker/refreshHighlightedBets');
    };

    const onlyFlaggedBets = computed(() => store.getters['betTicker/onlyFlaggedBets']);
    const setOnlyFlaggedBets = (newOnlyFlaggedBets) => {
      store.dispatch('betTicker/setOnlyFlaggedBets', newOnlyFlaggedBets);
    };

    watch(() => betTickerConfigurations.value, (newValue, oldValue) => {
      const newValueFiltered = filter(newValue, ({ enabled }) => enabled);
      if (oldValue?.length && !newValueFiltered?.length) {
        store.dispatch('betTicker/selectBetTicker', null);
        return;
      }
      if (selectedBetTicker.value?.betTickerId && !find(newValueFiltered, (ticker) => ticker.betTickerId === selectedBetTicker.value?.betTickerId)) {
        store.dispatch('betTicker/selectBetTicker', newValueFiltered[0]);
      }
    });

    onClickOutside(betTickerRef, () => {
      betTickerMenuToggled.value = false;
    });

    return {
      SIDEBAR_OPTIONS,
      setActiveSidebar,
      betTickerConfigurations,
      selectedBetTicker,
      selectBetTicker,
      createBetTicker,
      filteredBetTickerConfigurations,
      toggleShowDisabledTickers,
      betTickerMenuToggled,
      showDisabledTickers,
      betTickerRef,
      hasHighlightedBets,
      refreshHighlightedBets,
      onlyFlaggedBets,
      setOnlyFlaggedBets,
    };
  },
};
</script>
<style lang="scss">
.betTicker-header {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 0 0;
    .ticker-container {
      display: flex;
      height: 100%;
      height: 55px;
      align-items: center;
      .ticker-configuration {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 8px;
        box-shadow: inset 0px -4px 0px #F0F0F0;
        position: relative;

        .bet-ticker-item {
          height: 100%;
          display: flex;
          align-items: center;
          gap: 4px;
        }

        &.selected {
          box-shadow: inset 0px -4px 0px #003C3C;

          .menu-overlay {
            box-shadow: inset 0px -4px 0px #003C3C;
          }
        }

        &:hover {
          /* background-color: rgba(0,0,0, 0.03); */
          cursor: pointer;

          .menu-overlay {
            display: flex;
          }
        }

        .menu-overlay {
          background: linear-gradient(270.9deg, #FAFAFA 62.54%, rgba(255, 255, 255, 0) 86.2%);
          width: 55px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          align-items: center;
          justify-content: flex-end;
          padding-right: 8px;
          display: none;
          box-shadow: inset 0px -4px 0px #F0F0F0;
          .icon {
            width: 16px;
            height: 16px;
          }
        }

        .bet-ticker-dropdown {
          width: 180px;
          position: absolute;
          top: 44px;
          left: 8px;
          z-index: 999;
          border: 1px solid #F0F0F0;
          border-radius: 4px;
          box-shadow: 0px 2px 4px 0px #19141414;
          padding: 4px 0;
          min-height: 40px;
          background: #fff;

          .bet-ticker-dropdown-option {
            display: flex;
            height: 32px;
            padding: 0 8px;
            align-items: center;

            &:hover {
              background-color: rgba(0,0,0, 0.03);
              cursor: pointer;
            }

            .icon {
              height: 14px;
              width: 14px;
              margin-right: 4px;

              &.duplicate {
                svg {
                  path {
                    stroke-width: 1;
                    stroke: #191414;
                  }
                }
              }
            }

            &.delete {
              color: #FF2E2D;
              .icon {
                svg {
                  path {
                    fill: #FF2E2D;
                  }
                }
              }
            }
          }
        }
      }

      .new-ticker-container {
        display: flex;
        align-items: center;
        position: relative;
        .new-ticker {
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 8px 0 16px;
          border-left: 1px solid #F0F0F0;
          margin-left: 8px;

          &:hover {
            /* background-color: rgba(0,0,0, 0.03); */
            cursor: pointer;
          }

          .icon {
            height: 16px;
            width: 16px;
            margin-right: 4px;
          }

          &.no-tickers {
            border-left: 0;
            margin-left: 0px;
          }
        }
        .ticker-menu-dots {
          height: 16px;
          cursor: pointer;
        }
        .ticker-menu-dropdown {
          width: 180px;
          position: absolute;
          top: 44px;
          left: 8px;
          z-index: 999;
          border: 1px solid #F0F0F0;
          border-radius: 4px;
          box-shadow: 0px 2px 4px 0px #19141414;
          padding: 4px 0;
          min-height: 40px;
          background: #fff;

          .ticker-menu-option {
            display: flex;
            height: 32px;
            padding: 0 8px;
            align-items: center;

            &:hover {
              background-color: rgba(0,0,0, 0.03);
              cursor: pointer;
            }

            .icon {
              height: 14px;
              width: 14px;
              margin-right: 4px;
            }
          }
        }
      }
    }
    .filter-container {
        display: flex;
        align-items: center;
        gap: 8px;
        height: $betTickerHeaderPageHeight;

        .refresh-bets-button {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          background-color: #003C3C;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .icon {
            width: 16px;
            height: 16px;

            svg {
              path {
                fill: #fff;
              }
            }
          }

          &.disabled {
            background-color: #F0F0F0;
            cursor: default;
            .icon {
              svg {
                path {
                  fill: #A9A9A9;
                }
              }
            }
          }

          &:hover {
            background-color: #336363;
            .icon {
              svg {
                path {
                  fill: #fff;
                }
              }
            }
            &.disabled {
              background-color: #F0F0F0;
              cursor: default;
              .icon {
                svg {
                  path {
                    fill: #A9A9A9;
                  }
                }
              }
            }
          }
        }
    }

    .flagged-bets-filter {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #191414;
      cursor: pointer;
    }
}
</style>
